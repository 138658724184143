import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  AddWalletAmountMerchant,
  GetMerchantPaginate,
  GetBankAccountListBySelectedMerchantId,
} from "../../../Services/Api/merchant";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { Helmet } from "react-helmet-async";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from "use-debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import { Watch } from "@material-ui/icons";
import { set } from "date-fns/esm";

const motiveOptions = [
  { label: "CASH DEPOSIT", value: "CASH_DEPOSIT" },
  { label: "CHEQUE DEPOSIT", value: "CHEQUE_DEPOSIT" },
  { label: "BANK TRANSFER", value: "BANK_TRANSFER" },
];

const AddWalletAmount = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);

  const history = useNavigate();
  const [items, setItems] = useState("");
  const [photoID, setPhotoID] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [merchantListPagination, setMerchantListPagination] = useState([]);
  const [bankListPagination, setBankListPagination] = useState([]);
  const [logo, setLogo] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [titleName, setTitleName] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [iban, setIban] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [isBankShow, setIsBankShow] = useState(false);
  // const [disableField, setDisableField] = useState(false);
  const [bankCurrency, setBankCurrency] = useState("");
  const [currency, setCurrency] = useState("");

  const getMerchantList = async () => {
    setIsLoading(true);
    await GetMerchantPaginate({ limit: 10, page: 0, paginate: false })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.merchants.length > 0) {
          let merchatArr = res?.data?.data?.merchants;
          merchatArr = merchatArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
              currencyValue: value.primary_currency,
              // id:value.id
            };
          });
          setMerchantListPagination(merchatArr);
        } else {
          // ErrorToast("No Results.");
          setMerchantListPagination([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setMerchantListPagination([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const getBankAccountList = async (id) => {
    if (id) {
      setIsLoading(true);
      await GetBankAccountListBySelectedMerchantId(id)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.data?.accounts.length > 0) {
            let bankArr = res?.data?.data.accounts;
            bankArr = bankArr.map((value) => {
              return {
                label: value.title,
                value: value.id,
                title: value.title,
                currencyValue: value.currency,
                logo: value.logo,
                beneficiary: value.beneficiary,
                accountNumber: value.account_number,
                iban: value.iban,
                swiftCode: value.swift_code,
              };
            });
            setBankListPagination(bankArr);
            if (res.data.data.accounts.id == null) {
            }
          } else {
            setTitle({ ...title, destination_bank: null });
            // ErrorToast("No Results.");
            setBankListPagination([]);
            setIsBankShow(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setTitle({ ...title, destination_bank: null });
          setBankListPagination([]);
          setIsBankShow(false);
          // if (typeof err.response.data.message !== "undefined") {
          //   ErrorToast(err.response.data.message || "Server Error!!");
          // } else {
          //   ErrorToast(err?.message || "Server Error!!");
          // }
        });
    }
  };

  // const disable = () => {
  //   if (bankCurrency == currency) {
  //     setDisableField(true);
  //   } else {
  //     setDisableField(false);
  //   }
  // };

  const initialValues = {
    user_id: "",
    validation_controller: "",
    // deposited_currency: "",
    deposited_amount: "",
    on_behalf_of: name,
    date_of_payment: "",
    destination_bank: "",
    // transaction_currency: "",
    transaction_amount: "",
    motive: "",
    bank_reference: "",
    user_reference: "",
    comments: "",
    receipt_file: "",
    aed_conversion_rate: "",
  };

  const signInSchema = Yup.object().shape({
    user_id: Yup.string().nullable().required("Please select merchant"),
    // deposited_currency: Yup.string().nullable().required("Choose currency"),
    deposited_amount: Yup.number()
      .nullable()
      .positive("Must be greater then 0")
      .required("Deposited amount is required")
      .typeError("Please enter number only"),
    date_of_payment: Yup.date().required("Please select date"),
    destination_bank: Yup.object().nullable().required("Please select a bank"),
    motive: Yup.string().nullable().required("Please choose type of payment"),
    validation_controller: Yup.boolean(),
    // transaction_currency: Yup.string().nullable().required("Please select a transaction currency type"),
    transaction_amount: Yup.number()
      .nullable()
      .positive()
      .when("validation_controller", {
        is: true,
        then: Yup.string().required(
          "Credited / Transaction amount is required"
        ),
      }),
    bank_reference: Yup.string().required("Bank reference is required"),
    user_reference: Yup.string().required("User reference is required"),
    comments: Yup.string().nullable().max(200, "Enter 200 character only"),
    receipt_file: Yup.mixed().required("Receipt file is required"),
    aed_conversion_rate: Yup.number()
      .nullable()
      .positive("Must be greater then 0")
      .required("AED conversion rate is required")
      .typeError("Enter number only")
      .when("validation_controller", {
        is: true,
        then: Yup.string().required(
          "Credited / Transaction amount is required"
        ),
      }),
  });

  useEffect(() => {
    let items = JSON.parse(localStorage.getItem("userData"));
    if (items) {
      setId(items.id);
      setItems(items);
      setPhotoID(items.id);
      setName(items.name);
      getMerchantList();
      getBankAccountList();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Admin Add Wallet Amount</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Add Wallet Amount</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Admin/dashboard">Dashboard</Link>
                  </li>
                  {/* <li className="breadcrumb-item">
                    <Link to="/admin/merchant-transactions">Merchants Transactions</Link>
                  </li> */}
                  <li className="breadcrumb-item active">Add Wallet Amount</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/merchant-wallet-request"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div className="card-body">
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="cash-wallet-amount"
                    >
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        validateOnChange
                        onSubmit={async (values, { setSubmitting }) => {
                          const postObj = {
                            user_id: id,
                            motive: values.motive,
                            date_of_payment: format(
                              new Date(values.date_of_payment),
                              "yyyy-MM-dd"
                            ),
                            destination_bank: values.destination_bank.value,
                            on_behalf_of:
                              "CPAY (AL SAFFRON TRAVEL AND TOURISM LLC)",
                            deposited_currency: bankCurrency,
                            deposited_amount: values.deposited_amount,
                            transaction_currency: currency,
                            transaction_amount:
                              values.transaction_amount ||
                              values.deposited_amount,
                            bank_reference: values.bank_reference,
                            user_reference: values.user_reference,
                            comments: values.comments,
                            receipt_file: "96x96.png", //values.receipt_file,
                            aed_conversion_rate:
                              values.aed_conversion_rate || 1,
                          };
                          AddWalletAmountMerchant(postObj)
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message ||
                                  "Setting data has been update."
                              );
                              setSubmitting(false);
                              setTimeout(() => {
                                history("/admin/merchant-wallet-request");
                              }, 1000);
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                              setSubmitting(false);
                            });
                          setTimeout(() => {
                            setSubmitting(false);
                          }, 400);
                        }}
                      >
                        {(formik) => {
                          const {
                            touched,
                            errors,
                            setFieldValue,
                            handleChange,
                            isSubmitting,
                            values,
                          } = formik;
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="user_id">
                                          Choose Merchant{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={merchantListPagination}
                                          placeholder="Select merchant"
                                          onChange={(e) => {
                                            setFieldValue("user_id", e.value);
                                            setFieldValue(
                                              "Currency",
                                              e.currencyValue
                                            );
                                            setFieldValue("id", e.value);
                                            setCurrency(e.currencyValue);
                                            setId(e.value);
                                            getBankAccountList(e.value);
                                            setFieldValue(
                                              "transaction_amount",
                                              null
                                            );
                                            setFieldValue(
                                              "destination_bank",
                                              null
                                            );
                                          }}
                                          name="user_id"
                                        />
                                        <ErrorMessage
                                          name="user_id"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-dark">
                                          Date of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                          autoComplete="off"
                                          className={`form-control ${
                                            errors.date_of_payment &&
                                            touched.date_of_payment
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="date_of_payment"
                                          onChange={(date) =>
                                            setFieldValue(
                                              "date_of_payment",
                                              date
                                            )
                                          }
                                          selected={values.date_of_payment}
                                          placeholderText="mm-dd-yyyy"
                                          maxDate={new Date()}
                                        />
                                        <ErrorMessage
                                          name="date_of_payment"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>On Behalf of</label>
                                        <Field
                                          type="text"
                                          onChange={handleChange}
                                          className={`form-control ${
                                            errors.on_behalf_of &&
                                            touched.on_behalf_of
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="on_behalf_of"
                                          value={
                                            "CPAY (AL SAFFRON TRAVEL AND TOURISM LLC)"
                                          }
                                          disabled
                                        />
                                        <ErrorMessage
                                          name="on_behalf_of"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Destination/Bank{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={bankListPagination}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "destination_bank",
                                              e
                                            );
                                            if (
                                              values?.transaction_amount ||
                                              values?.aed_conversion_rate
                                            ) {
                                              setFieldValue(
                                                "transaction_amount",
                                                ""
                                              );
                                              setFieldValue(
                                                "aed_conversion_rate",
                                                ""
                                              );
                                            }
                                            setTitle(e.value);
                                            setTitleName(e.title || null);
                                            setLogo(e.logo);
                                            setBeneficiary(e.beneficiary);
                                            setAccountNumber(e.accountNumber);
                                            setIban(e.iban);
                                            setSwiftCode(e.swiftCode);
                                            setBankCurrency(e.currencyValue);
                                            if (!isBankShow) {
                                              setIsBankShow(true);
                                            }
                                            // disable();
                                            // if(e.currencyValue === "AED"){
                                            //   setDisableField(true)
                                            // }
                                          }}
                                          name="destination_bank"
                                          placeholder="Select bank"
                                          value={values.destination_bank}
                                        />
                                        <ErrorMessage
                                          name="destination_bank"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group ">
                                        <label>
                                          Amount Deposited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend ">
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              {bankCurrency || "-"}
                                            </span>
                                          </div>
                                          <Field
                                            type="text"
                                            placeholder="Enter deposited amount"
                                            aria-describedby="basic-addon3"
                                            className={`form-control ${
                                              errors.deposited_amount &&
                                              touched.deposited_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="deposited_amount"
                                            onChange={(e) => {
                                              var Tvalue = e.target.value;
                                              // var defaultOne = "1";
                                              const transaction_amount =
                                                parseFloat(
                                                  values?.transaction_amount ||
                                                    0
                                                );
                                              const deposited_amount =
                                                parseFloat(
                                                  values?.deposited_amount || 0
                                                );
                                              setFieldValue(
                                                "deposited_amount",
                                                Tvalue
                                              );
                                              if (Tvalue > 0 ) {
                                                setFieldValue(
                                                  "transaction_amount",
                                                  values.transaction_amount &&
                                                    values.aed_conversion_rate &&
                                                    values.deposited_amount
                                                    ? parseFloat(Tvalue) *
                                                        parseFloat(
                                                          values.aed_conversion_rate
                                                        )
                                                    : parseFloat(Tvalue)
                                                );
                                              }
                                              if (
                                                transaction_amount > 0 &&
                                                deposited_amount > 0
                                              ) {
                                                setFieldValue(
                                                  "aed_conversion_rate",
                                                  parseFloat(
                                                    values?.transaction_amount ||
                                                      0
                                                  ) /
                                                    parseFloat(
                                                      values?.deposited_amount ||
                                                        1
                                                    )
                                                );
                                              } else {
                                                setFieldValue(
                                                  "aed_conversion_rate",
                                                  "1"
                                                );
                                              }
                                            }}
                                            // onChange={(e) => {
                                            //   setFieldValue()
                                            //   // setFieldValue(
                                            //   //   "deposited_amount",
                                            //   //   e.target.value
                                            //   // );
                                            //   // setFieldValue(
                                            //   //   "transaction_amount",
                                            //   //   e.target.value / 90
                                            //   // );

                                            // }}
                                          />
                                        </div>
                                        <ErrorMessage
                                          name="deposited_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                        <label>
                                          Actual currency and amount been
                                          deposited
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Amount to be Credited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          {/* {values.transaction_amount != */}
                                          {/* values.deposited_amount && ( */}
                                          <>
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon3"
                                              >
                                                {currency || "-"}
                                              </span>
                                            </div>
                                            <Field
                                              type="text"
                                              placeholder="Enter credited amount"
                                              className={`form-control ${
                                                values?.destination_bank
                                                  ?.currencyValue !== "AED"
                                                  ? errors.transaction_amount &&
                                                    touched.transaction_amount
                                                    ? "input-error"
                                                    : null
                                                  : null
                                              }`}
                                              disabled={
                                                values?.destination_bank
                                                  ?.currencyValue === "AED"
                                              }
                                              name="transaction_amount"
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "transaction_amount",
                                                  e.target.value
                                                );
                                                if (
                                                  values.deposited_amount &&
                                                  values.transaction_amount
                                                ) {
                                                  setFieldValue(
                                                    "aed_conversion_rate",
                                                    (
                                                      e.target.value /
                                                      values.deposited_amount
                                                    ).toFixed(4)
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                          {/* )} */}
                                        </div>
                                        <ErrorMessage
                                          name="transaction_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                        <span>
                                          Conversion Rate:{" "}
                                          {values.transaction_amount != "" &&
                                          values.deposited_amount != "" ? (
                                            <>
                                              {" "}
                                              <span className="currency-type-text">
                                                {bankCurrency || ""}
                                              </span>{" "}
                                              {values.transaction_amount
                                                ? (
                                                    values.transaction_amount /
                                                    values.deposited_amount
                                                  ).toFixed(4)
                                                : null}{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <label>
                                          Amount and currency you claim to
                                          credit into your account
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Bank Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter bank reference"
                                          className={`form-control ${
                                            errors.bank_reference &&
                                            touched.bank_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="bank_reference"
                                          onBlur={(e) => {
                                            if (!values.user_reference) {
                                              setFieldValue(
                                                "user_reference",
                                                e.target.value
                                              );
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name="bank_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Your Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter user reference"
                                          className={`form-control ${
                                            errors.user_reference &&
                                            touched.user_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="user_reference"
                                          onChange={handleChange}
                                          value={values.user_reference}
                                        />
                                        <ErrorMessage
                                          name="user_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Type of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={motiveOptions}
                                          onChange={(e) => {
                                            setFieldValue("motive", e.value);
                                          }}
                                          name="motive"
                                          placeholder="Select type of payment"
                                        />
                                        <ErrorMessage
                                          name="motive"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    {/* {values.transaction_amount != */}
                                    {/* values.deposited_amount && ( */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          {" "}
                                          AED Conversion Rate{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            {/* <span className="input-group-text" id="inputGroup-sizing-default">{merchantCurrencyName || "-"}</span> */}
                                          </div>
                                          <Field
                                            type="text"
                                            className={`form-control ${
                                              errors.aed_conversion_rate &&
                                              touched.aed_conversion_rate
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="aed_conversion_rate"
                                            onChange={(e) => {
                                              if (
                                                values.deposited_amount &&
                                                values.transaction_amount
                                              ) {
                                                setFieldValue(
                                                  "aed_conversion_rate",
                                                  e.target.value
                                                );
                                                setFieldValue(
                                                  "transaction_amount",
                                                  (
                                                    values.deposited_amount *
                                                    e.target.value
                                                  ).toFixed(4)
                                                );
                                              }
                                            }}
                                            placeholder="Enter AED conversion rate"
                                            disabled={
                                              values?.destination_bank
                                                ?.currencyValue === "AED"
                                            }
                                          />
                                        </div>
                                        <ErrorMessage
                                          name="aed_conversion_rate"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    {/* )} */}

                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Comments</label>
                                        <Field
                                          as="textarea"
                                          rows={3}
                                          cols={5}
                                          type="text"
                                          placeholder="Enter comment"
                                          className={`form-control ${
                                            errors.comments && touched.comments
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="comments"
                                        />
                                        <ErrorMessage
                                          name="comments"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group dropzone-image-area">
                                    <label className="image-label">
                                      Receipt{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DragAndDropFile
                                      randerKey={debounceKey}
                                      maxFileSize={2000000} //2mb
                                      label="Drag and drop a image or click"
                                      fileTypes={[
                                        "image/*",
                                        ".pdf",
                                        ".docx",
                                        ".doc",
                                        ".pptx",
                                      ]}
                                      className={`form-control dropzoneBox ${
                                        errors.receipt_file &&
                                        touched.receipt_file
                                          ? "input-error"
                                          : null
                                      }`}
                                      handleChange={async (file) => {
                                        setFieldValue("receipt_file", file[0]);
                                        let imageData = file[0];
                                        const imagePostData = {
                                          user_id: id,
                                          file_name: imageData?.name,
                                          file_module: "Wallet_RECEIPT",
                                          mime_type: imageData?.type,
                                          document_sides: "NONE",
                                        };
                                        await fileUploadToBucket({
                                          data: imagePostData,
                                          file: imageData,
                                        })
                                          .then(async (res) => {
                                            setFieldValue(
                                              "receipt_file",
                                              res?.data?.data?.s3_key
                                            );
                                          })
                                          .catch((err) => {
                                            // if (
                                            //   typeof err.response.data.message !== "undefined"
                                            // ) {
                                            //   ErrorToast(
                                            //     err.response.data.message || "Server Error!!"
                                            //   );
                                            // } else {
                                            //   ErrorToast(err?.message || "Server Error!!");
                                            // }
                                          });
                                      }}
                                      acceptedFiles={fileTypes}
                                    />
                                    <ErrorMessage
                                      name="receipt_file"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                {/* col-md-8 */}
                                <div
                                  className={`col-md-4${
                                    !isBankShow ? ` d-none` : ``
                                  }`}
                                >
                                  <div>
                                    <img
                                      src={logo}
                                      alt="bank logo"
                                      className="imgcenter"
                                    />{" "}
                                  </div>
                                  <table className="table table-stripped table-responsive d-block">
                                    <tbody>
                                      <tr>
                                        <td style={{ width: "10%" }}>Bank</td>
                                        <th className="custom-table">
                                          {titleName}
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Holder / Beneficiary
                                        </td>
                                        <th className="custom-table">
                                          {beneficiary}
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Account Number
                                        </td>
                                        <th className="custom-table">
                                          {accountNumber}
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>IBAN</td>
                                        <th className="custom-table">{iban}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Swift Code
                                        </td>
                                        <th className="custom-table">
                                          {swiftCode}
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="text-center  mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content container-fluid absolute px-2">
                <footer className="text-center text-lg-start">
                  <div className="text-center pb-4">
                    Powered by Alsaffron System.
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWalletAmount;
